<template>
  <div
    class="revo-editable-field">
    <div
      class="revo-editable-field__text"
      v-show="readOnly"
      @click="handleClick"
      >
      <!-- {{ internalValue }} -->
      <slot>{{ value }}</slot>
    </div>
    <b-field
      v-show="!readOnly"
      >
      <b-input
        ref="input"
        :type="type"
        :value="value"
        v-bind="$attrs"
        @input="handleInput"
        @blur="handleBlur"
        @keyup.enter.native="handleKeyupEnter"
        >
      </b-input>
    </b-field>
  </div>
</template>

<script>
export default {
  /**
   * Only v-model OR v-bind value attribute OR v-bind value attribute + slot are to be used like so.
   */
  name: 'EditableField',
  inheritAttrs: false,
  data() {
    return {
      readOnly: true,
      internalValue: this.value,
    };
  },
  props: {
    value: {
      type: String,
      required: true,
      validator: value => !!value
    },
    type: {
      type: String,
      default: 'text',
      validator: value => ['text', 'textarea'].indexOf(value) !== -1
    }
  },
  methods: {
    handleClick() {
      this.readOnly = false;
      this.$refs.input.focus();
    },
    handleInput(value) {
      // After this component is created, moving forward,
      // data 'internalValue' should reflect the value captured by the input field.
      this.internalValue = value;
      this.$emit('input', value);
    },
    handleKeyupEnter() {
      // because we are using <b-input />
      // path to actually get the DOM input element
      this.$refs.input.$refs.input.blur();
    },
    handleBlur() {
      if (this.internalValue) {
        this.readOnly = true;
        this.$emit('confirm', this.internalValue);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.revo-editable-field {
  &__text {
    position: relative;
    padding-left: 10px;
    span.fa-pencil-alt {
      display: none;
    }
    &:hover {
      cursor: pointer;
      span.fa-pencil-alt {
        display: inline-block;
      }
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border: 1px solid #d5d5d5;
        left: 0;
        top: 0;
        border-radius: 4px;
      }
    }
  }
}
</style>
