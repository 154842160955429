<template>
  <div class="editor">
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive, }">
      <div class="menubar"
           :class="{'is-hidden': !editable}"
      >

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.bold() }"
          @click="commands.bold"
        >
          <icon name="bold" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.italic() }"
          @click="commands.italic"
        >
          <icon name="italic" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.strike() }"
          @click="commands.strike"
        >
          <icon name="strike" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.underline() }"
          @click="commands.underline"
        >
          <icon name="underline" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.code() }"
          @click="commands.code"
        >
          <icon name="code" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.paragraph() }"
          @click="commands.paragraph"
        >
          <icon name="paragraph" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 1 }) }"
          @click="commands.heading({ level: 1 })"
        >
          H1
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 2 }) }"
          @click="commands.heading({ level: 2 })"
        >
          H2
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 3 }) }"
          @click="commands.heading({ level: 3 })"
        >
          H3
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.bullet_list() }"
          @click="commands.bullet_list"
        >
          <icon name="ul" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.ordered_list() }"
          @click="commands.ordered_list"
        >
          <icon name="ol" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.blockquote() }"
          @click="commands.blockquote"
        >
          <icon name="quote" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.code_block() }"
          @click="commands.code_block"
        >
          <icon name="code" />
        </button>

        <button
          class="menubar__button"
          @click="commands.horizontal_rule"
        >
          <icon name="hr" />
        </button>

        <button
          class="menubar__button"
          @click="commands.undo"
        >
          <icon name="undo" />
        </button>

        <button
          class="menubar__button"
          @click="commands.redo"
        >
          <icon name="redo" />
        </button>

        <button
          class="menubar__button"
          @click="showImagePrompt(commands.image)"
        >
          <icon name="image" />
        </button>

      </div>
    </editor-menu-bar>

    <editor-content class="editor__content" :editor="editor" />
    <br />
    <div
      v-if="!readOnly"
      class="buttons"
    >
      <b-button
        class="is-small"
        v-if="!editable"
        @click="enableEdit">
          Edit
          <span class="fa fa-xs fa-pencil-alt is-grey-light"></span>
      </b-button>
      <slot
        v-if="!editable"
      ></slot>
      <b-button
        class="is-primary is-small"
        v-if="editable"
        @click="handleSave">
          Save
          <span class="fa fa-xs is-grey-light"></span>
      </b-button>
      <b-button
        class="is-light is-small"
        v-if="editable"
        @click="disableEdit">
          Cancel
          <span class="fa fa-xs is-grey-light"></span>
      </b-button>
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Image,
} from 'tiptap-extensions';
import Icon from '../Icon/index.vue';

export default {
  name: 'EditorComponent',
  components: {
    EditorContent,
    EditorMenuBar,
    Icon,
  },
  props: {
    content: {
      type: String,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    },
  },
  data() {
    const { content } = this;
    return {
      editable: this.readOnly,
      editor: new Editor({
        editable: this.readOnly,
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Image(),
        ],
        content,
        // https://github.com/ueberdosis/tiptap/issues/133
        onUpdate: ({ getHTML }) => {
          const state = getHTML();
          this.$emit('input', state);
        },
      }),
    };
  },
  watch: {
    editable() {
      this.editor.setOptions({
        editable: this.editable,
      });

      // if (this.editable) {
      //   this.editor.focus();
      // }
    }
  },
  methods: {
    getHTML() {
      return this.editor.getHTML();
    },
    handleSave() {
      this.$emit('editor:save', this.editor.getHTML());

      this.disableEdit();
    },
    enableEdit() {
      this.editable = true;
    },
    disableEdit() {
      this.editable = false;
    },
    showImagePrompt(command) {
      const src = prompt('Enter the url of your image here');
      if (src !== null) {
        command({ src });
      }
    },
  },
  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss">
  .editor {
  .ProseMirror[contenteditable="true"] {
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
    padding: 10px;
    min-height: 118px;
  }
  .ProseMirror.ProseMirror-focused[contenteditable="true"] {
    border-color: #8c67ef;
    box-shadow: 0 0 0 0.125em rgba(140, 103, 239, 0.25);
  }
  }
</style>
