<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Create a Story</p>
    </header>
    <section class="modal-card-body">
      <div class="columns is-fullheight">
        <div class="column is-8">
          <b-field
            label="Title"
            label-for="title"
            :type="{'is-danger': errors.has('title')}"
            :message="errors.first('title')">
            <b-input
              placeholder="e.g. Create a Dashboard"
              id="title"
              type="text"
              v-model="title"
              name="title"
              v-validate="'required'"
              >
            </b-input>
          </b-field>
          <b-field label="Description">
          </b-field>
          <Editor
            ref="editor"
            :content="''"
            v-model="description"
            :readOnly="true"
          >
          </Editor>
          <b-field
            label="Type"
            label-for="storyTypeSelected"
            :type="{'is-danger': errors.has('storyTypeSelected')}"
            :message="errors.first('storyTypeSelected')"
            >
            <b-select
              expanded
              name="storyTypeSelected"
              id="storyTypeSelected"
              v-model="storyTypeSelected"
              v-validate="'required'">
              <option
                v-for="option in STORY_TYPES"
                :value="option"
                :key="option">
                {{ option }}
              </option>
            </b-select>
          </b-field>
          <b-field
            label="Priority"
            label-for="storyPrioritySelected"
            :type="{'is-danger': errors.has('storyPrioritySelected')}"
            :message="errors.first('storyPrioritySelected')"
            >
            <b-select
              expanded
              name="storyPrioritySelected"
              id="storyPrioritySelected"
              v-model="storyPrioritySelected"
              v-validate="'required'">
              <option
                v-for="option in STORY_PRIORITIES"
                :value="option"
                :key="option">
                {{ option }}
              </option>
            </b-select>
          </b-field>
          <b-field
            label="Status"
            label-for="statusSelected"
            :type="{'is-danger': errors.has('statusSelected')}"
            :message="errors.first('statusSelected')"
            >
            <b-select
              expanded
              name="statusSelected"
              id="statusSelected"
              v-model="statusSelected"
              v-validate="'required'">
              <option
                v-for="status in BOARD_STATUSES"
                :value="status._id"
                :key="status._id">
                {{ status.title }}
              </option>
            </b-select>
          </b-field>
          <b-field
            label="Project"
            label-for="projectSelected"
            :type="{'is-danger': errors.has('projectSelected')}"
            :message="errors.first('projectSelected')"
            >
            <b-select
              expanded
              name="projectSelected"
              id="projectSelected"
              v-model="projectSelected"
              v-validate="'required'">
              <option
                v-for="option in projectOptions"
                :value="option"
                :key="option">
                {{ option }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-4 is-sidebar-menu">
          <b-field
            label="Assignee"
            label-for="assigneeSelected"
            :type="{'is-danger': errors.has('assigneeSelected')}"
            :message="errors.first('assigneeSelected')"
            >
            <b-select
              expanded
              name="assigneeSelected"
              id="assigneeSelected"
              v-model="assigneeSelected"
              v-validate="'required'">
              <option
                v-for="option in activeWorkspaceMembers"
                :key="option._id"
                :value="option._id">
                {{ option.fullName }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$parent.close()">Close</button>
      <b-button class="button is-primary"
                @click="handleSubmit"
                native-type="submit" :loading="submitBtnLoading">Create</b-button>
    </footer>
  </div>
</template>

<script>
import { map } from 'lodash';
import { mapGetters } from 'vuex';
import Editor from '@/components/containerComponents/Editor.component.vue';
import { CONSTANTS as WORKSPACE_CONSTANTS, } from '@/store/modules/workspace/workspace.constants';
import { CONSTANTS as BOARD_CONSTANTS } from '@/store/modules/board/board.constants';
import { CONSTANTS as PROJECT_CONSTANTS, } from '@/store/modules/project/project.constants';
import { CONSTANTS as STORY_CONSTANTS } from '@/store/modules/story/story.constants';
import { CONSTANTS as USER_CONSTANTS } from '@/store/modules/user/user.constants';

export default {
  components: { Editor, },
  props: {
    projectTitle: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      title: '',
      description: '',
      storyTypeSelected: 'Feature',
      storyPrioritySelected: 'Major',
      statusSelected: '',
      projectOptions: [],
      projectSelected: '',
      assigneeSelected: '',
      submitBtnLoading: false,
    };
  },
  created() {
    this.statusSelected = this.BOARD_STATUSES[0]._id;

    this.projectOptions = this.projectsTitle;

    if (this.projectTitle) {
      this.projectSelected = this.projectTitle;
    } else if (this.projectOptions.length) {
      const [firstProjectTitle] = this.projectOptions;
      this.projectSelected = firstProjectTitle;
    }
  },
  computed: {
    ...mapGetters([
      USER_CONSTANTS.GETTERS.CURRENT_USER,
      USER_CONSTANTS.GETTERS.USERS,
      WORKSPACE_CONSTANTS.GETTERS.ACTIVE_WORKSPACE,
      WORKSPACE_CONSTANTS.GETTERS.activeWorkspaceMembers,
      BOARD_CONSTANTS.GETTERS.BOARD_STATUSES,
      PROJECT_CONSTANTS.GETTERS.ACTIVE_WORKSPACE_PROJECTS,
      STORY_CONSTANTS.GETTERS.STORY_TYPES,
      STORY_CONSTANTS.GETTERS.STORY_PRIORITIES,
    ]),
    projectsTitle() {
      const projects = this.ACTIVE_WORKSPACE_PROJECTS;
      const projectsTitle = map(projects, project => project.title);
      return projectsTitle;
    },
  },
  methods: {
    getProjectID(projectTitle) {
      const projects = this.ACTIVE_WORKSPACE_PROJECTS;
      const project = projects.find(proj => (
        proj.title === projectTitle));
      return project._id;
    },
    async handleSubmit() {
      this.submitBtnLoading = true;

      if (await this.isFormValid()) {
        this.createAStory();
      } else {
        this.submitBtnLoading = false;
        this.$buefy.toast.open({
          message: 'Form is not valid! Please check the fields.',
          duration: 3000,
          type: 'is-danger',
          position: 'is-bottom'
        });
      }
    },
    async isFormValid() {
      const isValid = await this.$validator.validateAll();
      return isValid;
    },
    createAStory() {
      const {
        title,
        description,
        storyTypeSelected,
        storyPrioritySelected,
        statusSelected,
        projectSelected,
        assigneeSelected: owner,
        [WORKSPACE_CONSTANTS.GETTERS.ACTIVE_WORKSPACE]: workspace,
        [USER_CONSTANTS.GETTERS.CURRENT_USER]: creator,
      } = this;

      this.$store.dispatch(STORY_CONSTANTS.ACTIONS.STORY_CREATE, {
        title,
        description,
        storyType: storyTypeSelected,
        priority: storyPrioritySelected,
        status: statusSelected,
        workspace,
        owner,
        project: this.getProjectID(projectSelected),
        creator,
      })
        .then(() => {
          this.submitBtnLoading = false;
          this.$store.dispatch(WORKSPACE_CONSTANTS.ACTIONS.WORKSPACE_STORIES_REQUEST)
            .then(() => {
              this.$store.dispatch(WORKSPACE_CONSTANTS.ACTIONS.WORKSPACE_BOARD_REQUEST)
                .then(() => {
                  this.$emit('storyCreated');
                  /* This destroys the <modal-form></modal-form> component instance. */
                  this.$parent.close();
                })
                .catch(error => console.error(error));
            })
            .catch(error => console.error(error));
        })
        .catch(error => console.error(error));
    }
  },
};
</script>

<style>

</style>
